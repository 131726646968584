<template>
  <validation-observer ref="simpleRules">
    <b-overlay :show="show" rounded="sm">
      <b-form>
        <b-card>
          <b-card-title
            ><h2>{{ $t("IdeaForm.Publish_idea") }}</h2>
          </b-card-title>
          <br />
          <b-row>
            <!--  name-->
            <b-col md="6">
              <b-form-group>
                <label class="required">{{ $t("IdeaForm.Idea_name") }} </label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Idea name"
                >
                  <b-form-input
                    v-model="createIdeaProfile.name"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('IdeaForm.Idea_name')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Idea belongs to"
                rules="required"
              >
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label class="required" for="owner_type"
                    >{{ $t("IdeaForm.Idea_belongs_to") }}
                  </label>
                  <v-select
                    id="State"
                    v-model="createIdeaProfile.selectedOwnerType"
                    :options="
                      $store.state.locale.locale == 'ar'
                        ? createIdeaProfile.OwnerTypeOptionArabic
                        : createIdeaProfile.OwnerTypeOption
                    "
                    :selectable="
                      (option) => !option.value.includes('nothing_selected')
                    "
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Country"
                rules="required"
              >
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label class="required" for="Country">{{
                    $t("IdeaForm.country_reside")
                  }}</label>

                  <v-select
                    id="Country"
                    v-model="createIdeaProfile.selectedCountry"
                    :options="countries"
                    :selectable="
                      (option) => !option.value.includes('nothing_selected')
                    "
                    label="text"
                    @input="
                      retreiveStates(createIdeaProfile.selectedCountry.value)
                    "
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="State"
                rules="required"
              >
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label class="required" for="State">{{
                    $t("IdeaForm.state_reside")
                  }}</label>

                  <v-select
                    id="State"
                    v-model="createIdeaProfile.selectedState"
                    :options="statesOptions"
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="City"
                  rules="required"
                >
                  <label class="required" for="City">{{
                    $t("IdeaForm.city_reside")
                  }}</label>

                  <b-form-input
                    id="facebook"
                    v-model="createIdeaProfile.city"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('IdeaForm.city_reside')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Industry"
                rules="required"
              >
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label class="required" for="Industry"
                    >{{ $t("IdeaForm.industries_belong") }}
                  </label>

                  <div id="app">
                    <treeselect
                      v-model="createIdeaProfile.industries"
                      :multiple="true"
                      label="Select Industries ."
                      :placeholder="$t('common.Select')"
                      :options="industries"
                      @input="handeTreeSelect"
                      :normalizer="
                        $store.state.locale.locale == 'ar'
                          ? normalizerArabic
                          : normalizer
                      "
                    />
                  </div>
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="highlights"
                  :rules="`required|max:${$maxHighlightsProfile}`"
                >
                  <label class="required" for="highlights"
                    >{{ $t("IdeaForm.Brief_overview") }}
                  </label>

                  <b-form-textarea
                    id="highlights"
                    v-model="createIdeaProfile.highlights"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('IdeaForm.Brief_overview')"
                    rows="2"
                    max-rows="2"
                    no-auto-shrink
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Reference"
                  rules="required"
                >
                  <label class="required" for="Reference">{{
                    $t("IdeaForm.Include_references")
                  }}</label>

                  <b-form-textarea
                    id="Reference"
                    v-model="createIdeaProfile.Reference"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('IdeaForm.Include_references_p')"
                    rows="2"
                    max-rows="2"
                    no-auto-shrink
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Owner Details"
                  rules="required"
                >
                  <label class="required" for="Owner-Details">{{
                    $t("IdeaForm.description_owner")
                  }}</label>

                  <b-form-textarea
                    id="Owner-Details"
                    v-model="createIdeaProfile.OwnerDetails"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('IdeaForm.description_owner')"
                    rows="3"
                    max-rows="3"
                    no-auto-shrink
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="description"
                  rules="required"
                >
                  <label class="required" for="description">{{
                    $t("IdeaForm.description_idea")
                  }}</label>
                  <b-form-textarea
                    id="description"
                    v-model="createIdeaProfile.description"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('IdeaForm.description_idea')"
                    rows="3"
                    max-rows="3"
                    no-auto-shrink
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Category"
                rules="required"
              >
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label class="required" for="Category"
                    >{{ $t("IdeaForm.needs_from_sharing") }}
                  </label>
                  <v-select
                    id="Category"
                    v-model="createIdeaProfile.selectedCategory"
                    :options="categories"
                    :selectable="
                      (option) => !option.value.includes('nothing_selected')
                    "
                    label="text"
                    @input="resetDataSaleType()"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md="6"
              v-if="
                createIdeaProfile.selectedCategory.value ==
                '62a584a04c2a541d1939a10c'
              "
            >
              <validation-provider
                #default="{ errors }"
                name="sale type"
                rules="required"
              >
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label class="required" for="saleType"
                    >{{ $t("IdeaForm.How_sell_idea") }}
                  </label>

                  <v-select
                    id="saleType"
                    v-model="createIdeaProfile.selectedSaleType"
                    :options="
                      $store.state.locale.locale == 'ar'
                        ? createIdeaProfile.SaleTypeOptionArabic
                        : createIdeaProfile.SaleTypeOption
                    "
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="
                createIdeaProfile.selectedSaleType.value == '1' &&
                createIdeaProfile.selectedCategory.value ==
                  '62a584a04c2a541d1939a10c'
              "
              md="6"
            >
              <b-row>
                <b-col md="4">
                  <validation-provider
                    #default="{ errors }"
                    name="Currency"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label class="required" for="Currency">{{
                        $t("IdeaForm.Currency")
                      }}</label>

                      <v-select
                        id="Currency"
                        v-model="createIdeaProfile.SelectedCurrency"
                        :options="currencies"
                        :selectable="
                          (option) => !option.value.includes('select_value')
                        "
                        label="text"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="8">
                  <validation-provider
                    #default="{ errors }"
                    name="Price"
                    rules="required|integer"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label class="required" for="Price">{{
                        $t("IdeaForm.Price")
                      }}</label>

                      <b-form-input
                        id="Price"
                        v-model="createIdeaProfile.Price"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('IdeaForm.My_Own_Price')"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="status"
                rules="required"
              >
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label class="required" for="Status">{{
                    $t("IdeaForm.Status")
                  }}</label>

                  <v-select
                    id="Status"
                    v-model="createIdeaProfile.selectedStatus"
                    :options="
                      $store.state.locale.locale == 'ar'
                        ? createIdeaProfile.StatusOptionArabic
                        : createIdeaProfile.StatusOption
                    "
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="blockchain exchange"
                rules="required"
              >
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label class="required" for="blockchain_exchange">{{
                    $t("IdeaForm.blockchain_exchange")
                  }}</label>
                  <v-select
                    id="blockchain_exchange"
                    v-model="createIdeaProfile.selectedExchange"
                    :options="
                      $store.state.locale.locale == 'ar'
                        ? createIdeaProfile.ExchangeOptionArabic
                        : createIdeaProfile.ExchangeOption
                    "
                    :selectable="
                      (option) => !option.value.includes('nothing_selected')
                    "
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('IdeaForm.Facebook')"
                label-for="facebook"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Facebook"
                  rules="url"
                >
                  <b-form-input
                    id="facebook"
                    v-model="createIdeaProfile.facebookUrl"
                    :state="errors.length > 0 ? false : null"
                    placeholder="https://facebook.com/abc"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('IdeaForm.Instagram')"
                label-for="twitter"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Instagram"
                  rules="url"
                >
                  <b-form-input
                    id="twitter"
                    v-model="createIdeaProfile.twitterUrl"
                    :state="errors.length > 0 ? false : null"
                    placeholder="https://Instagram.com/abc"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('IdeaForm.LinkedIn')"
                label-for="linked-in"
              >
                <validation-provider
                  #default="{ errors }"
                  name="LinkedIn"
                  rules="url"
                >
                  <b-form-input
                    id="linked-in"
                    v-model="createIdeaProfile.linkedinUrl"
                    :state="errors.length > 0 ? false : null"
                    placeholder="https://linkedin.com/abc"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group :label="$t('IdeaForm.Website')" label-for="Other">
                <validation-provider
                  #default="{ errors }"
                  name="Other"
                  rules="url"
                >
                  <b-form-input
                    id="Other"
                    v-model="createIdeaProfile.OtherUrl"
                    :state="errors.length > 0 ? false : null"
                    placeholder="https://other.com/abc"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="main image"
                  :rules="`required|size:${$sizeFilesProfile}`"
                >
                  <label class="required" for="idea-icon"
                    >{{ $t("IdeaForm.main_image") }}
                  </label>
                  <b-form-file
                    v-model="icon"
                    required
                    :state="errors.length > 0 ? false : null"
                    accept="image/jpeg, image/png, image/gif"
                    drop-placeholder="Drop file here..."
                    :placeholder="$t('common.Choose_file_drop')"
                    :browse-text="$t('common.Browse')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="idea-images"
                  :rules="`size:${$sizeFilesProfile}`"
                >
                  <label class="" for="idea-images">{{
                    $t("IdeaForm.idea_images")
                  }}</label>
                  <b-form-file
                    v-model="images"
                    accept="image/jpeg, image/png, image/gif"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('common.Choose_file_drop')"
                    :browse-text="$t('common.Browse')"
                    drop-placeholder="Drop file here..."
                    :multiple="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="idea-documents"
                  :rules="`size:${$sizeFilesProfile}`"
                >
                  <label class="" for="idea-documents">{{
                    $t("IdeaForm.idea_documents")
                  }}</label>
                  <b-form-file
                    v-model="docouments"
                    accept="application/pdf, application/vnd.ms-excel,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    :state="errors.length > 0 ? false : null"
                    multiple
                    :placeholder="$t('common.Choose_file_drop')"
                    :browse-text="$t('common.Browse')"
                    drop-placeholder="Drop file here..."
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- submit button -->
            <b-col cols="12">
              <b-alert
                v-if="errorMessage"
                show
                variant="warning"
                class="warning"
                style="color: red"
                >{{ errorMessage }}</b-alert
              >
              <br />
              <b-button
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                {{ $t("IdeaForm.Submit") }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </b-overlay>
  </validation-observer>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import "../../../../layouts/landpage/ValidationFrom.js";

import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCard,
  BFormFile,
  BFormDatepicker,
  BFormTextarea,
  BFormInvalidFeedback,
  BCardTitle,
  BOverlay,
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    vSelect,
    BFormFile,

    // eslint-disable-next-line vue/no-unused-components
    BFormDatepicker,
    BFormTextarea,
    BFormInvalidFeedback,
    BCardTitle,
    Treeselect,
    BOverlay,
    // VuePhoneNumberInput,    BAlert,
  },
  data() {
    return {
      show: false,
      icon: null,
      images: [],
      docouments: [],
      errorMessage: "",
      createIdeaProfile: {
        selectedState: null,
        name: "",
        selectedOwnerType: "",
        OwnerDetails: "",
        highlights: "",
        description: "",
        selectedCountry: null,
        selectedIndustry: "",
        city: "",
        twitterUrl: "",
        facebookUrl: "",
        OtherUrl: "",
        linkedinUrl: "",
        selectedExchange: "",
        selectedCategory: "",
        selectedSaleType: "",
        SelectedCurrency: "",
        Price: "",
        selectedStatus: "",
        Reference: "",
        icon: [],
        images: [],
        docouments: [],
        industries: [],
        // phoneNumber: '',
        // phoneCode: '',
        // email: '',
        OwnerTypeOption: [
          { value: "individual", text: "Individual" },
          { value: "group", text: "Group" },
        ],
        OwnerTypeOptionArabic: [
          { value: "individual", text: "فرد" },
          { value: "group", text: "مجموعة" },
        ],
        ExchangeOption: [
          { value: "true", text: "Yes" },
          { value: "false", text: "No" },
        ],
        ExchangeOptionArabic: [
          { value: "true", text: "نعم" },
          { value: "false", text: "لا" },
        ],
        SaleTypeOption: [
          { value: "1", text: "My Own Price" },
          { value: "2", text: "Auction" },
        ],
        SaleTypeOptionArabic: [
          { value: "1", text: "سعري الخاص" },
          { value: "2", text: "مزاد" },
        ],
        StatusOption: [
          { value: "1", text: "Complete " },
          { value: "2", text: "Incomplete" },
        ],
        StatusOptionArabic: [
          { value: "1", text: "مكتملة" },
          { value: "2", text: "غير مكتملة" },
        ],
      },

      companyPopover: {
        EBITDA:
          "Investors/Buyers evaluate your business proposal based on this information. It is best to communicate this information upfront instead of wasting your time and theirs.",
        MonthlySales:
          "Investors/Buyers evaluate your business proposal based on this information. It is best to communicate this information upfront instead of wasting your time and theirs.",
        AnnualSales:
          "Investors/Buyers evaluate your business proposal based on this information. It is best to communicate this information upfront instead of wasting your time and theirs.",
        CompanyImage:
          "Kindly upload your facility / office pictures as profiles with photos get higher rating and visibility. You can upload jpg, bmp, png, tiff, gif files which are less than 3MB in size.",
        CompanyDocuments:
          "Profiles with documents get higher rating and visibility. Documents uploaded are accessible only to members introduced to you. You can upload word, excel, powerpoint, pdf, jpg, bmp, png, gif, mp4 files which are less than 3MB in size.",
        CompanyProof:
          "You can attach documents such as tax certificate, incorporation certificate, govt registration certificate or evidence of revenue such as bank statement, screenshot of sales, etc. Uploading this document will help our review team to verify and approve your profile faster. You can upload word, excel, powerpoint, pdf, jpg, bmp, png, gif files which are less than 3MB in size.",
        companyPhotos:
          "You can attach documents such as tax certificate, incorporation certificate, govt registration certificate or evidence of revenue such as bank statement, screenshot of sales, etc. Uploading this document will help our review team to verify and approve your profile faster. You can upload word, excel, powerpoint, pdf, jpg, bmp, png, gif files which are less than 3MB in size.",
      },
    };
  },
  computed: {
    ...mapGetters({
      categories: "profile/getIdeaCategories",
      industries: "profile/getAllIndustries",
      currencies: "currency/getCurrencies",
      countries: "country/getCountries",
      statesOptions: "country/getAllStates",
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      fetchCategories: "profile/retreiveCategories",
      fetchIndustries: "profile/retreiveIndustries",
      createIdea: "profile/createIdea",
      fetchCurrencies: "currency/retreiveCurrencies",
      fetchCountries: "country/retreiveCountries",
      fetchStates: "country/retreiveStates",
    }),

    resetDataSaleType() {
      this.createIdeaProfile.selectedSaleType = "";
      this.createIdeaProfile.SelectedCurrency = "";
      this.createIdeaProfile.Price = "";
    },

    normalizerArabic(node) {
      return {
        id: node.id,
        label: node.arabicName != null ? node.arabicName : node.label,
        children: node.children,
      };
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children,
      };
    },
    handeTreeSelect(data) {
      if (data.length > 6) {
        this.createIdeaProfile.industries.length = 6;
      }
    },
    // onUpdate(payload) {
    //   // console.log(payload);
    //   this.isValidNumber = payload.isValid
    //   this.createIdeaProfile.phoneCode = payload.countryCode
    // },
    retreiveStates(data) {
      this.fetchStates(data);
    },
    removeThisFile(thisFile) {
      this.$refs.MyDropzone.removeFile(thisFile);
      console.log("File removed!");
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.show = true;
          const ideaData = new FormData();
          ideaData.append("name", this.createIdeaProfile.name);
          ideaData.append(
            "typeofOwner",
            this.createIdeaProfile.selectedOwnerType.value
          );
          ideaData.append(
            "profileCountry",
            this.createIdeaProfile.selectedCountry.value
          );
          ideaData.append(
            "profileIndustries",
            JSON.stringify(this.createIdeaProfile.industries)
          );
          if (this.createIdeaProfile.facebookUrl) {
            ideaData.append("facebook", this.createIdeaProfile.facebookUrl);
          }
          if (this.createIdeaProfile.twitterUrl) {
            ideaData.append("twitter", this.createIdeaProfile.twitterUrl);
          }
          if (this.createIdeaProfile.linkedinUrl) {
            ideaData.append("linkedIn", this.createIdeaProfile.linkedinUrl);
          }
          if (this.createIdeaProfile.OtherUrl) {
            ideaData.append("otherURLS", this.createIdeaProfile.OtherUrl);
          }
          ideaData.append(
            "blockchainExchange",
            this.createIdeaProfile.selectedExchange.value
          );
          ideaData.append(
            "category",
            this.createIdeaProfile.selectedCategory.value
          );
          if (this.createIdeaProfile.selectedSaleType) {
            ideaData.append(
              "saleType",
              this.createIdeaProfile.selectedSaleType.value
            );
          }

          if (this.createIdeaProfile.SelectedCurrency) {
            ideaData.append(
              "currencyId",
              this.createIdeaProfile.SelectedCurrency.value
            );
          }
          if (this.createIdeaProfile.Price) {
            ideaData.append("priceNumber", this.createIdeaProfile.Price);
          }
          ideaData.append(
            "ideaStatus",
            this.createIdeaProfile.selectedStatus.value
          );
          ideaData.append("ideaReference", this.createIdeaProfile.Reference);
          ideaData.append("description", this.createIdeaProfile.description);
          ideaData.append(
            "detailsofOwner",
            this.createIdeaProfile.OwnerDetails
          );
          ideaData.append("highlights", this.createIdeaProfile.highlights);
          ideaData.append("profileIcon", this.icon);
          // ideaData.append('profileImages', this.createIdeaProfile.images)
          // ideaData.append('profileDocuments', this.createIdeaProfile.docouments)
          // eslint-disable-next-line no-restricted-syntax

          if (this.images) {
            for (const i of Object.keys(this.images)) {
              ideaData.append("profileImages", this.images[i]);
            }
          }
          if (this.docouments) {
            for (const i of Object.keys(this.docouments)) {
              ideaData.append("profileDocuments", this.docouments[i]);
            }
          }

          ideaData.append("profileCity", this.createIdeaProfile.city);
          ideaData.append(
            "profileState",
            this.createIdeaProfile.selectedState.value
          );
          ideaData.append("profileType", "idea");
          // eslint-disable-next-line no-underscore-dangle
          ideaData.append("userId", this.user._id);
          this.createIdea(ideaData)
            .then((response) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$t("messages.Added_successfully"),
                  icon: "CoffeeIcon",
                  variant: "success",
                },
              });
              this.show = false;
              this.$router.push({ name: "ideas-listAll" });
            })
            .catch((error) => {
              this.show = false;
              this.errorMessage = error.response.data.error.message;
            });
        }
      });
    },
  },
  mounted() {
    this.fetchCategories();
    this.fetchIndustries();
    this.fetchCurrencies();
    this.fetchCountries();
    this.configValidate();
  },
};
</script>

<style type="text/css">
@charset "UTF-8";

[dir] label {
  font-size: 14px;
}
[dir] .form-control {
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  font-size: 15px;
}

[dir] .vue-form-wizard .wizard-card-footer .wizard-footer-right .wizard-btn {
  background-color: #366ce3;
  border-color: #2d5dc7;
}
[dir] .vs__selected {
  background-color: #366ce3;
}
.col-md-6 {
  margin-bottom: 5px;
}

.required:after {
  content: " *";
  color: red;
}
[dir] .dropzone.dz-clickable {
  cursor: pointer;
  min-height: 150px;
  max-height: 260px;
  overflow-y: scroll;
}
</style>
